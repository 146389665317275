import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./api/axiosInstance";
import { useUser } from "./context/UserContext";

const Login = () => {
  // Hook to navigate between routes
  const navigate = useNavigate();

  // Get the login function from UserContext
  const { login } = useUser();

  // Formik hook to handle form state and validation
  const formik = useFormik({
    initialValues: {
      email: "", // Initial email value
      password: "", // Initial password value
    },
    // Validation schema using Yup
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address") // Validate email format
        .required("Email is required"), // Email is required
      password: Yup.string()
        .required("Password is required"), // Password is required
    }),
    // Function to handle form submission
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        // Prepare the request payload
        const newValues = {
          params: {
            login: values.email,
            password: values.password,
          },
        };
        // Make an API call to the login endpoint
        const { data } = await axiosInstance.post("/login", newValues);

        // Extract the result from the response
        const responseResult = data?.result?.data ?? {};

        if (responseResult?.access_token) {
          // If login is successful, store user data and navigate to home
          const userData = {
            partner_name: responseResult.partner_name,
            partner_id: responseResult.partner_id,
            access_token: responseResult.access_token,
          };
          login(userData);
          navigate("/home");
        } else if (data.status === "Error") {
          // Handle error response from the server
          console.error("Login error: ", data);
        } else {
          console.log(data);
        }
      } catch (error) {
        // Handle any errors that occur during the request
        console.error("Request error: ", error);
        setErrors({ server: error?.response?.data?.message });
      } finally {
        // Stop the form submission loading state
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="flex items-center justify-center h-full py-[50px] lg:py-[100px] bg-gray-100">
      <div className="w-full lg:w-[500px] max-w-md bg-white rounded-lg border border-slate-100 shadow-md p-8">
        <h2 className="text-2xl font-bold mb-6 text-center text-textPrimary">Login</h2>
        <form onSubmit={formik.handleSubmit}>
          {/* Server error message */}
          {formik.errors.server && (
            <div className="mb-4 text-red-600">{formik.errors.server}</div>
          )}
          {/* Email input field */}
          <div className="mb-4">
            <label
              className="block text-textPrimary text-[14px] font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              {...formik.getFieldProps("email")}
              className={`w-full px-3 py-2 border rounded-lg focus:outline-none FontHelvetica focus:ring ${
                formik.touched.email && formik.errors.email
                  ? "border-red-500"
                  : "focus:ring-blue-500"
              }`}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.email}
              </div>
            )}
          </div>
          {/* Password input field */}
          <div className="mb-6">
            <label
              className="block text-textPrimary text-[14px] font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              {...formik.getFieldProps("password")}
              className={`w-full px-3 py-2 border rounded-lg focus:outline-none FontHelvetica focus:ring ${
                formik.touched.password && formik.errors.password
                  ? "border-red-500"
                  : "focus:ring-blue-500"
              }`}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.password}
              </div>
            )}
          </div>
          {/* Submit button */}
          <div className="flex items-center justify-center">
            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="bg-primary text-textPrimary font-bold py-2 px-4 rounded-lg hover:bg-primary-700 focus:outline-none"
            >
              {formik.isSubmitting ? "Logging in..." : "Login"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
