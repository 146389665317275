// src/components/PublicRoute.js

import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
// import { decrypt } from "../utils/cryptoUtils";
import { useUser } from "../context/UserContext";

const PublicRoute = ({ element: Component, restricted, ...rest }) => {
  const { user } = useUser();
  // console.log(user?.partner_id);

  // Retrieve and decrypt the data from localStorage
  // const retrievedEncryptedData = localStorage.getItem("_d");
  // const user = retrievedEncryptedData ? decrypt(retrievedEncryptedData) : {};
  // console.log('PublicRoute');
  // console.log(user);

  return (
    <Routes>
      <Route
        {...rest}
        element={
          user?.partner_id > 0 && restricted ? (
            <Navigate to="/home" replace />
          ) : (
            Component
          )
        }
      />
    </Routes>
  );
};

export default PublicRoute;
