function Footer() {
  return (
    <>
      <footer className="bg-primary-bg">
        <div className="container mx-auto px-4 py-8">
          <div className="flex flex-col sm:flex-row justify-center items-center sm:justify-between">
            <div className="mb-4 sm:mb-0">
              <a href="https://www.equiti.com/gold/en/">
                <img
                  src="https://eq-cdn.equiti-me.com/website/documents/Equiti_Gold_Footer_logo.svg"
                  className="h-8 w-auto"
                  alt="Equiti Gold Footer logo"
                />
              </a>
            </div>
            <div className="flex mt-4 sm:mt-0 space-x-4">
              <a
                href="https://www.linkedin.com/company/equiti-gold/"
                title="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://eq-cdn.equiti-me.com/website/documents/linkedin_7mDS4co.svg"
                  className="w-6 h-6 invert-[65%]"
                  alt="LinkedIn"
                />
              </a>

              <a
                href="https://www.youtube.com/@EquitiGold"
                title="YouTube"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://eq-cdn.equiti-me.com/website/documents/youtube_kDZ5hEl.svg"
                  className="w-6 h-6 invert-[65%]"
                  alt="YouTube"
                />
              </a>

              <a
                href="https://x.com/EquitiGold"
                title="X"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://eq-cdn.equiti-me.com/website/documents/TwitterX.svg"
                  className="w-6 h-6 invert-[65%]"
                  alt="X (Twitter)"
                />
              </a>

              <a
                href="https://www.facebook.com/profile.php?id=61560482283939"
                title="Meta (Facebook)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://eq-cdn.equiti-me.com/website/documents/facebook_OZDbkDP.svg"
                  className="w-6 h-6 invert-[65%]"
                  alt="Facebook"
                />
              </a>

              <a
                href="https://www.instagram.com/equitigold/"
                title="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://eq-cdn.equiti-me.com/website/documents/instagram_BOVNLvi.svg"
                  className="w-6 h-6 invert-[65%]"
                  alt="Instagram"
                />
              </a>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row justify-between mt-8">
            <div className="grid grid-cols-2 sm:grid-cols-5 gap-4 sm:w-1/2">
              <div>
                <h6 className="font-semibold mb-2 text-white text-[14px]">Equiti Gold</h6>
                <hr className="mb-2 text-gray invert-[65%]" />
                <a
                  href="https://www.equiti.com/gold/en/about-us/"
                  className="text-gray underline FontHelvetica text-[14px]"
                >
                  About us
                </a>
              </div>
              <div>
                <h6 className="font-semibold mb-2 text-white text-[14px]">Services</h6>
                <hr className="mb-2 text-gray invert-[65%]" />
                <a
                  href="https://www.equiti.com/gold/en/services/"
                  className="text-gray underline FontHelvetica text-[14px]"
                >
                  Services
                </a>
              </div>
              <div className="flex flex-col">
                <h6 className="font-semibold mb-2 text-white text-[14px]">Download</h6>
                <hr className="mb-2 text-gray invert-[65%]" />
                <a
                  href="https://apps.apple.com/app/equiti-gold/id6474134526"
                  className="text-gray underline FontHelvetica text-[14px]"
                >
                  iOS app
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.equiti.gold.tokenization.app&pli=1"
                  className="text-gray underline FontHelvetica text-[14px]"
                >
                  Android app
                </a>
              </div>
            </div>

            <div className="mt-8 sm:mt-0 sm:w-1/8">
              <h6 className="font-semibold mb-2 text-white text-[14px]">Support</h6>
              <hr className="mb-2 text-gray invert-[65%]" />

              <ul className="space-y-4">
                <li className="flex items-start">
                  <img
                    src="https://eq-cdn.equiti-me.com/website/documents/faqs_JcdF61K.svg"
                    className="w-6 h-6 mr-2 invert-[100%]"
                    alt="FAQs"
                  />
                  <a
                    href="https://www.equiti.com/gold/en/frequently-asked-questions/"
                    className="text-gray underline FontHelvetica text-[14px]"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FAQs
                  </a>
                </li>

                <li className="flex items-start">
                  <img
                    src="https://eq-cdn.equiti-me.com/website/documents/email_jdEIIQc.svg"
                    className="w-6 h-6 mr-2 invert-[100%]"
                    alt="Email"
                  />
                  <a
                    href="mailto:gold@equiti.com"
                    className="text-gray underline FontHelvetica text-[14px]"
                  >
                    Email
                  </a>
                </li>

                <li className="flex items-start">
                  <img
                    src="https://eq-cdn.equiti-me.com/website/documents/phone_talk_mvMQuZ3.svg"
                    className="w-6 h-6 mr-2 invert-[100%]"
                    alt="Phone Talk"
                  />
                  <a
                    href="tel:+97144579800"
                    className="text-gray underline FontHelvetica text-[14px]"
                  >
                    Phone
                  </a>
                </li>

                <li className="flex items-start">
                  <img
                    src="https://eq-cdn.equiti-me.com/website/documents/location_0dNEuwf.svg"
                    className="w-6 h-6 mr-2 invert-[100%]"
                    alt="Location"
                  />
                  <a
                    href="https://maps.app.goo.gl/VXb9fiNCJtCvap389"
                    className="text-gray underline max-w-[200px] FontHelvetica text-[14px]"
                  >
                    18 A-B, Gold (AU) Tower, Cluster I, PO Box 117060 Jumeirah
                    Lake Towers, Dubai, United Arab Emirates.
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="text-left mt-8 text-gray-600">
            <p className="text-gray FontHelvetica text-[14px]">
              Equiti Gold is the trade name for Equiti Commodities DMCC, a
              company licensed by the Dubai Multi Commodities Center under
              license number 846279 with its registered address at B3-18-01
              &amp; B3-18-02, Gold Tower, Jumeirah Lake Towers, Dubai, United
              Arab Emirates.
            </p>
          </div>

          <div className="text-center lg:text-left mt-4 text-gray">
            <p className="FontHelvetica text-[14px]">
              © 2024 Equiti Gold, All Rights Reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
