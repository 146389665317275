// src/utils/cryptoUtils.js

import { secret } from '../setup';

const CryptoJS = require('crypto-js'); // If using ES6 modules, use `import CryptoJS from 'crypto-js';`

// Define your secret key (store this securely)
const secretKey = secret;

/**
 * Encrypts a JavaScript object into a string.
 *
 * @param {Object} data - The data to encrypt.
 * @returns {string} The encrypted string.
 */
export function encrypt(data) {
  // Convert the object to a JSON string
  const jsonString = JSON.stringify(data);
  
  // Encrypt the JSON string using AES encryption
  return CryptoJS.AES.encrypt(jsonString, secretKey).toString();
}

/**
 * Decrypts an encrypted string back into a JavaScript object.
 *
 * @param {string} encryptedData - The encrypted string.
 * @returns {Object} The decrypted data.
 */
export function decrypt(encryptedData) {
  // Decrypt the string
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  
  // Convert the decrypted string back into a JSON object
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}
