import { useLocation } from "react-router-dom";
import { useUser } from "./context/UserContext";
import { useEffect, useState } from "react";
import { decrypt } from "./utils/cryptoUtils";

function Header() {
  // Retrieve and decrypt user data from localStorage
  const retrievedEncryptedData = localStorage.getItem("_d");
  // eslint-disable-next-line
  const userData = retrievedEncryptedData
    ? decrypt(retrievedEncryptedData)
    : {};
  const { user, logout, loginSetUser } = useUser();

  // State to manage the menu's open/close state in mobile view
  const [menuOpen, setMenuOpen] = useState(false);

  // State to determine if the header should be hidden on scroll
  const [isScrolled, setIsScrolled] = useState(false);

  // React Router's hook to navigate programmatically
  // const navigate = useNavigate();

  // React Router's hook to access the current location
  const location = useLocation();

  // Context hook to access the logout function
  // const { logout } = useUser();

  // Function to handle scroll event and update `isScrolled` state
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsScrolled(scrollTop > 50); // Hide header if scrolled more than 50px
  };

  // Set up the scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Set up the scroll event listener when the component mounts
  useEffect(() => {
    if (userData?.partner_id > 0 && !user) loginSetUser(userData);
  }, [loginSetUser, user, userData]);

  // Retrieve the user's name if available
  const name = userData?.partner_name ?? "";

  return (
    <>
      <header className="fixed top-0 w-full">
        {/* Top black bar that hides on scroll */}
        <div
          className={`fixed top-0 left-0 w-full z-50 transition-transform duration-300 hidden lg:block bg-black ${
            isScrolled ? "-translate-y-full" : "translate-y-0"
          }`}
        >
          <div className="bg-gray-800 py-1">
            <div className="container mx-auto flex items-center justify-between px-4">
              {/* Placeholder for company information or logo */}
              <div className="company-information">
                {/* Add company logo or information here */}
              </div>

              {/* Language selection dropdown */}
              <div className="relative">
                <button className="py-2 px-4 flex items-center text-gray">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-globe text-gray mr-2"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="2" y1="12" x2="22" y2="12"></line>
                    <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10z"></path>
                  </svg>
                  EN
                  <svg
                    className="ml-2 w-4 h-4 text-gray"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>
                {/* Language dropdown menu */}
                <ul className="absolute right-0 mt-2 w-48 bg-white text-black rounded shadow-lg hidden group-hover:block">
                  <li>
                    <a
                      href="/gold/ar/"
                      className="block px-4 py-2 hover:bg-gray-100"
                    >
                      العربيّة
                    </a>
                  </li>
                  {/* Add more languages as needed */}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Primary Navigation */}
        <nav
          className={`bg-white shadow-lg py-0 lg:py-2 ${
            isScrolled ? "mt-0" : "lg:mt-[48px]"
          }`}
        >
          <div className="container mx-auto flex items-center justify-between px-4">
            {/* Logo */}
            <a
              className="navbar-brand hidden lg:block"
              href="https://www.equiti.com/gold/en/"
            >
              <img
                src="https://eq-cdn.equiti-me.com/website/documents/Equiti_Gold_logo.svg"
                style={{ height: 30, width: "auto" }}
                alt="Equiti Gold logo"
              />
            </a>

            {/* Mobile view header */}
            <div className="flex items-center justify-between w-full lg:hidden py-2">
              <a
                className="navbar-brand"
                href="https://www.equiti.com/gold/en/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://eq-cdn.equiti-me.com/website/documents/Equiti_Gold_logo.svg"
                  style={{ height: 30, width: "auto" }}
                  alt="Equiti Gold logo"
                />
              </a>
              <div className="flex items-center gap-3 mr-4">
                {/* Display user name if available */}
                {name && (
                  <span className="text-textPrimary font-bold">Hi, {name}</span>
                )}
              </div>
            </div>

            {/* Desktop view navigation links */}
            <div
              className="py-0 flex items-center hidden lg:flex "
              id="navbarNavAltMarkup"
              role="dialog"
            >
              <div className="flex flex-col lg:flex-row items-center lg:items-center justify-center gap-3 px-3 lg:px-0">
                <a
                  href="https://www.equiti.com/gold/en/"
                  className="nav-link px-0 FontHelvetica text-textPrimary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Home
                </a>
                <a
                  href="https://www.equiti.com/gold/en/services/"
                  className="nav-link px-0 FontHelvetica text-textPrimary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Services
                </a>
                <a
                  href="https://www.equiti.com/gold/en/frequently-asked-questions/"
                  className="nav-link px-0 FontHelvetica text-textPrimary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Support
                </a>
                <a
                  href="https://www.equiti.com/gold/en/about-us/"
                  className="nav-link px-0 FontHelvetica text-textPrimary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About us
                </a>
              </div>
            </div>

            {/* Desktop view download button or logout button */}
            <div className="hidden lg:flex items-center gap-3">
              {location.pathname === "/home" ? (
                <>
                  {name && (
                    <span className="text-textPrimary font-medium FontHelvetica">
                      Hi, {name}
                    </span>
                  )}
                  <button
                    type="submit"
                    onClick={() => {
                      // localStorage.clear();
                      // navigate("/");
                      logout();
                    }}
                    className="bg-primary text-textPrimary font-bold py-2 px-4 rounded-lg hover:bg-primary-700 focus:outline-none"
                  >
                    Logout
                  </button>
                </>
              ) : (
                <a
                  href="https://www.equiti.com/gold/en/download/"
                  className="bg-primary hover:bg-primary-700 text-textPrimary py-2 px-4 rounded"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DOWNLOAD
                </a>
              )}
            </div>

            {/* Mobile view hamburger menu icon */}
            <div className="lg:hidden">
              <button
                onClick={() => setMenuOpen(!menuOpen)}
                className="text-white focus:outline-none"
              >
                {/* Hamburger Icon */}
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="#000"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  ></path>
                </svg>
              </button>
            </div>
          </div>

          {/* Mobile view navigation links */}
          <div
            className={`${
              menuOpen ? "block" : "hidden"
            } lg:hidden lg:items-center lg:w-auto w-full mt-4`}
          >
            <ul className="lg:flex lg:gap-4 text-white">
              {location.pathname !== "/home" && (
                <li className="mb-4">
                  <a
                    href="https://www.equiti.com/gold/en/download/"
                    className="bg-primary hover:bg-primary-700 text-grey py-2 px-4 rounded ml-4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    DOWNLOAD
                  </a>
                </li>
              )}
              <li>
                <a
                  href="https://www.equiti.com/gold/en/"
                  className="block lg:inline-block py-2 text-textPrimary border-b w-fit ml-4 FontHelvetica"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="https://www.equiti.com/gold/en/services/"
                  className="block lg:inline-block py-2 text-textPrimary border-b w-fit ml-4 FontHelvetica"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="https://www.equiti.com/gold/en/frequently-asked-questions/"
                  className="block lg:inline-block py-2 text-textPrimary border-b w-fit ml-4 FontHelvetica"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Support
                </a>
              </li>
              <li>
                <a
                  href="https://www.equiti.com/gold/en/about-us/"
                  className="block lg:inline-block py-2 px-4 text-textPrimary FontHelvetica"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About us
                </a>
              </li>
              {location.pathname === "/home" && (
                <li className="ml-4 my-4 pb-4">
                  <button
                    type="submit"
                    onClick={() => {
                      // localStorage.clear();
                      // navigate("/");
                      logout();
                    }}
                    className="bg-primary text-textPrimary font-bold py-2 px-4 rounded-lg hover:bg-primary-700 focus:outline-none"
                  >
                    Logout
                  </button>
                </li>
              )}
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
