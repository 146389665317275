// src/api/axiosInstance.js

import axios from 'axios';
import { backendUrl } from '../setup';

const axiosInstance = axios.create({
  baseURL: backendUrl, // Set your base API URL here
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage or context
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    // config.headers['Host'] = 'dev.equiti.gold';
    // config.headers['Host'] = '*';

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    console.log(error);
    if (error.response && error.response.status === 401) {
      // Handle unauthorized error (e.g., redirect to login)
      // You can also clear the token here if needed
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
