// src/App.js

import React from "react";
import { HashRouter as Router } from "react-router-dom";
import Layout from "./Layout";
import Login from "./Login";
import Home from "./Home";
// import Error404 from "./Error404";
import { UserProvider } from "./context/UserContext";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
// import { decrypt } from "./utils/cryptoUtils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NoInternetConnection from "./NoInternetConnection";

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Layout>
          <NoInternetConnection>
            <ToastContainer />
            {/* <Routes> */}
            {/* Public Route: Login */}
            <PublicRoute path="/" element={<Login />} restricted={true} />

            {/* Private Route: Home */}
            <PrivateRoute path="/home" element={<Home />} />
            
            {/* <PublicRoute path="*" element={<Error404 />} /> */}

            {/* Add more routes here as needed */}
            {/* </Routes> */}
          </NoInternetConnection>
        </Layout>
      </Router>
    </UserProvider>
  );
};

export default App;
