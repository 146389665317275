import React, { useState } from "react";
import { useUser } from "./context/UserContext";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./api/axiosInstance";
import { decrypt } from "./utils/cryptoUtils";
import { toast } from "react-toastify";

const Home = () => {
  // React Router's hook to navigate programmatically
  const navigate = useNavigate();

  // Context hook to access the user and logout function
  const { logout } = useUser();

  // State to manage the visibility of the confirmation popup
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // State to manage the loading status during the deletion process
  const [loading, setLoading] = useState(false);

  /**
   * Function to delete the user's account.
   * Retrieves and decrypts the user's data from localStorage,
   * sends a POST request to deactivate the user, and handles the response.
   */
  const deleteAccount = async () => {
    try {
      // Retrieve and decrypt the data from localStorage
      const retrievedEncryptedData = localStorage.getItem("_d");
      const decryptedData = retrievedEncryptedData
        ? decrypt(retrievedEncryptedData)
        : {};

      // Set loading state to true while processing
      setLoading(true);

      // Send request to deactivate the user
      const { data } = await axiosInstance.post(
        `/object/res.partner/${decryptedData?.partner_id}/deactivate_user`,
        {}
      );
      // console.log(data);
      // console.log(data.result);

      if (data.result) {
        toast.success("Account deleted successfully");
        // If successful, log out the user and navigate to the home page
        logout();
        navigate("/");
      } else if (data.status === "Error") {
        // Handle error case
        setLoading(false);
        console.error("Error");
      } else {
        // Handle unexpected response
        setLoading(false);
        console.error(data);
      }
    } catch (error) {
      // Catch and log any errors that occur during the process
      setLoading(false);
      console.error(error);
    } finally {
      // This block runs regardless of the try/catch outcome
      console.log("Finally");
    }
  };

  /**
   * Function to handle the click event on the delete button.
   * Shows the confirmation popup if not already loading.
   */
  const handleDeleteClick = () => {
    if (!loading) setIsPopupVisible(true);
  };

  /**
   * Function to confirm the account deletion.
   * Calls the deleteAccount function and hides the confirmation popup.
   */
  const confirmDelete = () => {
    deleteAccount();
    setIsPopupVisible(false);
  };

  /**
   * Function to cancel the deletion process.
   * Hides the confirmation popup.
   */
  const cancelDelete = () => {
    setIsPopupVisible(false);
  };

  return (
    <div className="flex justify-center items-center h-full py-[50px] lg:py-[100px] bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md text-center mx-4 border border-slate-100 ">
        <h2 className="text-3xl font-bold mb-4 text-textPrimary">
          Delete Account
        </h2>
        <p className="text-textPrimary mb-6 FontHelvetica">
          Are you sure you want to delete your account? <br />
          Deleting your account will remove all of your data including:
          <ul className="list-disc list-inside text-left text-textPrimary mb-6 ml-6">
            <li>Account information</li>
            <li>Location information</li>
            <li>Personal information</li>
            <li>Financial information</li>
            <li>Photos and videos</li>
            <li>Files and docs</li>
            <li>Application activity</li>
          </ul>
        </p>
        <button
          onClick={() => {
            if (!loading) handleDeleteClick();
          }}
          className="bg-red-600 text-white font-bold py-2 px-6 rounded-lg inline-flex items-center hover:bg-red-700 focus:outline-none focus:ring focus:ring-red-300 FontHelvetica"
        >
          {loading ? "Deleting..." : "Delete Permanently"}
        </button>
      </div>

      {isPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-30 backdrop-blur-sm">
          <div className="bg-white p-6 rounded shadow-lg">
            <p className="mb-4 FontHelvetica text-textPrimary">
              Are you sure you want to delete your account? <br />
              Please note that this is a permanent action and cannot be undone.
            </p>
            <div className="flex justify-end">
              <button
                onClick={cancelDelete}
                className="bg-gray-300 text-textPrimary py-2 px-4 rounded hover:bg-gray-400 mr-2 FontHelvetica"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (!loading) confirmDelete();
                }}
                className="bg-red-600 text-white font-bold py-2 px-6 rounded-lg inline-flex items-center hover:bg-red-700 focus:outline-none focus:ring focus:ring-red-300 FontHelvetica"
              >
                {loading ? "Deleting..." : "Delete Permanently"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
