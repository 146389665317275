// src/components/PrivateRoute.js

import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
// import { decrypt } from "../utils/cryptoUtils";
import { useUser } from "../context/UserContext";

const PrivateRoute = ({ element: Component, ...rest }) => {
  const { user } = useUser();
// console.log(user?.partner_id);
  // // Retrieve and decrypt the data from localStorage
  // const retrievedEncryptedData = localStorage.getItem("_d");
  // const user = retrievedEncryptedData ? decrypt(retrievedEncryptedData) : {};
  // console.log('PrivateRoute');
  // console.log(user);
  return (
    <Routes>
      <Route
        {...rest}
        element={user?.partner_id > 0 ? Component : <Navigate to="/" replace />}
      />
    </Routes>
  );
};

export default PrivateRoute;
