import React, { createContext, useState, useContext } from "react";
import { encrypt } from "../utils/cryptoUtils";
// import { useNavigate } from "react-router-dom";
// import axiosInstance from "../api/axiosInstance";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  //   const navigate = useNavigate();

  const [user, setUser] = useState(null); // null means not authenticated

  const login = (userData) => {
    setUser(userData); // Set the authenticated user
    localStorage.setItem("token", userData?.access_token); // Optionally store in localStorage

    // Encrypt the data
    const encryptedData = encrypt(userData);

    // Store the encrypted data in localStorage
    localStorage.setItem("_d", encryptedData);
  };

  const loginSetUser = (userData) => {
    // console.log('loginSetUser');
    setUser(userData); // Set the authenticated user
  };

  const logout = async () => {
    setUser(null); // Clear user data
    localStorage.removeItem("token"); // Optionally remove from localStorage
    localStorage.removeItem("_d"); // Optionally remove from localStorage
  };

  return (
    <UserContext.Provider value={{ user, login, logout, loginSetUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
